
export function verticalBarMarginHandle(str: string) {
    const len = str.length;
    if (len <= 7) return "0 20px";
    if (len <= 17) return "0 10px";
    return "0 5px";
}

// 字符串是否全是中文
export function isWholeZhStr(str: string) {
    const noSpaceStr = _filterSpace(str);
    const wholeZhRegex = /^[\u4e00-\u9fa5]+$/i;
    return wholeZhRegex.test(noSpaceStr);
}

// 字符串是否全是英文
export function isWholeEnStr(str: string) {
    const noSpaceStr = _filterSpace(str);
    const wholeEnRegex = /^[a-z]+$/i;
    return wholeEnRegex.test(noSpaceStr);
}

// 去掉空格拼接字符串
function _filterSpace(str: string) {
    // 把尾部空格去掉
    const tmpStr = str.trim();
    // 当字符串含有空格就返回过滤掉空格的新字符串
    const spaceRegex = /\s/;
    if (spaceRegex.test(tmpStr)) {
        return tmpStr.split(" ").join("")
    }
    return str
}

const egBase = ['郭沫若：“世上没有绝望的处境，只有对处境绝望的人。”', '鉴于对人类家庭所有成员的固有尊严及其平等的和不移的权利的承认，乃是世界自由、正义与和平的基础。', '太阳并没有一种特定的颜色，因为它是一个发光体。在地球上，我们看到的太阳的颜色通常是黄色或橙色，但这是由于地球大气层的影响造成的。在空间中观察太阳时，它看起来是一个白色的球体，因为它所发射的光线包含了所有的颜色。', '创造性思维和创新能力：虽然AI可以处理复杂的数据和任务，但它无法涵盖所有情况和出现的问题。因此，人们应该专注于开发创造性思维和创新能力，以解决那些需要人的判断和创造力的问题。', '战略和领导力：AI可以处理大量数据和分析，但它无法制定整体战略和决策。因此，人们应该将精力集中在领导力和战略制定上，以确保企业或组织的长期成功。', '人际关系和协作能力：虽然AI可以执行大量重复性任务，但它无法代替人际关系和协作能力。因此，人们应该专注于建立良好的人际关系和协作能力，以促进团队和组织的成功。', '总之，随着AI技术的不断发展和变化，人们应该将注意力转移到那些无法被AI代替的领域，以创造更多价值并实现长期成功。']

export function formatZhEnTemplatePreview(content: string) {
    const rightList = parseContent(content)
    const patchList:string[] = []
    rightList.forEach((item) => {
        if(/<img([^<]*)src="([^"]+)"([^<]*)>/.test(item)) {
            // img标签的时候
            patchList.push(`<p class="img-center">${item}</p>`)
        } else {
            const index = Math.round(Math.random() * 10) % egBase.length;
            const leftFloat = `<p class="left-float">（初次预览状态下，翻译无法启用）示例中文${index}：${egBase[index]}</p>`;
            const rightFloat = `<p class="right-float">${item}</p>`;
            const clearBoth = `<p class="clear-both"></p>`
            patchList.push(`${leftFloat}${rightFloat}${clearBoth}`)
        }
    })
    return patchList.join("")
}

export function formatZhEnTemplateUpdatePreview(content: string, zhList: string[]) {
    const rightList = parseContent(content)
    const patchList:string[] = []
    rightList.forEach((item, i) => {
        if(/<img([^<]*)src="([^"]+)"([^<]*)>/.test(item)) {
            // img标签的时候
            patchList.push(`<p class="img-center">${item}</p>`)
        } else {
            let leftFloat = '';
            const zhItem = zhList[i]
            if(zhItem && (rightList.length >= zhList.length)) {
                leftFloat = `<p class="left-float">（当前启用的是上一次发布后翻译好的内容）${zhList[i]}</p>`;
            } else {
                const index = Math.round(Math.random() * 10) % egBase.length;
                leftFloat = `<p class="left-float">示例中文${index}：${egBase[index]}</p>`;
            }           
            const rightFloat = `<p class="right-float">${item}</p>`;
            const clearBoth = `<p class="clear-both"></p>`
            patchList.push(`${leftFloat}${rightFloat}${clearBoth}`)
        }
    })
    return patchList.join("")
}

export function formatZhTemplateContent(zhList: string[], enList: string[]) {
    const resList : string[] = [];
    if(!zhList) return ""
    zhList.forEach((item, i) => {
        if(item === 'image-placeholder') {
            resList.push(`<p class="img-center">${enList[i]}</p>`)
        } else {
            resList.push(`<p>${item}</p>`)
        }
    })
    return resList.join("")
}

export function formatZhEnTemplateContent(zhList: string[], enList: string[]) {
    const resList : string[] = [];
    if(!zhList) return ""
    zhList.forEach((item, i) => {
        if(item === 'image-placeholder') {
            resList.push(`<p class="img-center">${enList[i]}</p>`)
        } else {
            const leftFloat = `<p class="left-float">${item}</p>`;
            const rightFloat = `<p class="right-float">${enList[i]}</p>`;
            const clearBoth = `<p class="clear-both"></p>`;
            resList.push(`${leftFloat}${rightFloat}${clearBoth}`)
        }
    })
    return resList.join("")
}


export function parseContent(content: string) {
    const divEl = document.createElement("div");
    divEl.innerHTML = content;
    const pList = divEl.querySelectorAll('p');
    const resList: string[] = [];
    pList.forEach((item) => {
        if(item.innerText.trim().length > 0) {
            resList.push(item.innerText)
        } else if (item.querySelector('img')) {
            resList.push(item.innerHTML)
        }
    })
    return resList
}