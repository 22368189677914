import * as React from "react";
import * as ReactDOM from "react-dom";
import * as mockxhr from "mockxhr";
import * as _ from "lodash";
import * as util from "../util";
import { RobotIcon } from "../components/TagIcon"
import { Box, Item, Icon } from ".";
import { Popover, Button, Tabs } from "antd";
import "../styles/desktop/ThesisDetail2.less";
import { htmlToPlain } from "../util";
import { actions } from "../flux";

const { TabPane } = Tabs;

interface Props {
  item: any;
  preview?: boolean;
  onSelectPaper?: any;
  paperIndex: number;
}

interface State {
  collapseAuthors: boolean;
  showAuthors: boolean;
  showSummary: boolean;
  showZhSummary: boolean;
  zhSummary: string;
  zhSummaryErr: string;
  isSummaryZh: boolean;
  db: any;
  periodical: any;
  zhEnTemplate: string;
  source: any;
}


export default class Detail extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapseAuthors: false,
      showAuthors: false,
      showSummary: false,
      showZhSummary: false,
      zhSummary: "",
      zhSummaryErr: "",
      isSummaryZh: false,
      db: null,
      periodical: {},
      zhEnTemplate: '',
      source: null
    };
  }


  componentDidMount() {
    let item = this.props.item;
    const updateSource = item.source
    if(updateSource.sourceType !== "DEFAULT") {
      mockxhr.GET(`/api/source/${ "id" in updateSource && updateSource.id ? updateSource.id : item.sourceKey}`, {}, resp => {
        this.setState({source: resp})
      })
    } else {
      this.setState({source: updateSource})
    }
    // 左中右英双语模板，分为预览模式和线上模式
    const isPreview = this.props.preview;
    if(item.template) {
      let leftZhRightEnTemplate = isPreview ? ('zhTextList' in item ? util.formatZhEnTemplateUpdatePreview(item.content, item.zhTextList) : util.formatZhEnTemplatePreview(item.content)) : util.formatZhEnTemplateContent(item.zhTextList, item.enTextList);
      this.setState({zhEnTemplate: leftZhRightEnTemplate})
    }

    let paperList = item.paperList || [];
    let selectedPaper = paperList[this.props.paperIndex] || {};
    // 判断是否为中文摘要
    if (JSON.stringify(selectedPaper) !== "{}") {
      console.log('@@@@@',selectedPaper)
      if(selectedPaper.summary===undefined){
        util.toast.error("论文摘要信息不能为空")
      }else{
        const summaryZh = selectedPaper.summary.match(/[\u4e00-\u9fa5]/g);
        if (summaryZh && (summaryZh.length > Math.round(selectedPaper.summary.length / 2))) {
          this.setState({ isSummaryZh: true });
        }
      }
    }

    let div = ReactDOM.findDOMNode(this.refs.authors) as HTMLElement;
    if (div) {
      let rect = div.getBoundingClientRect() as ClientRect;
      if (rect.height > 40) this.setState({ collapseAuthors: true });
    }
    mockxhr.POST(
      `/api/periodical/seaid`,
      {
        query: selectedPaper.checkname || selectedPaper.journal,
      },
      (resp) => {
        this.setState({
          periodical: resp.hits[0],
        });
      }
    );
  }

  componentDidUpdate(preProps, preState) {
    if (preProps.paperIndex !== this.props.paperIndex) {
      let item = this.props.item;
      let paperList = item.paperList || [];
      let selectedPaper = paperList[this.props.paperIndex] || {};
      let div = ReactDOM.findDOMNode(this.refs.authors) as HTMLElement;
      if (div) {
        let rect = div.getBoundingClientRect() as ClientRect;
        if (rect.height > 40) this.setState({ collapseAuthors: true });
      }
      mockxhr.POST(
        `/api/periodical/seaid`,
        {
          query: selectedPaper.checkname || selectedPaper.journal,
        },
        (resp) => {
          this.setState({
            periodical: resp.hits[0],
          });
        }
      );
    }
  }

  render() {
    let { item } = this.props;
    let paperList = item.paperList || [];
    let selectedPaper = paperList[this.props.paperIndex] || {};

    return (
      <div style={{ background: "white" }}>
        <div style={{ padding: 24, borderBottom: "1px solid #ebebeb" }}>
          <Box start>
            <Item
              flex
              style={{ fontWeight: 700, fontSize: 28, lineHeight: "48px" }}
            >
              {/* 如果有id的话就是修改情况下的双语模式预览 */}
              {this.props.preview && item.template ? ('id' in item  ? item.title : '示例标题：在人类和小鼠中都发现了调节酶的巨噬细胞，这为将小鼠的发现转化为人类疗法打开了大门' ) : item.title} 
            </Item>
            {util.minWidth(24)}
          </Box>
          {item.template ? <Box start style={{ marginTop: 10 }}>
          <Item flex
              style={{ fontWeight: "bold", fontSize: 20, lineHeight: "32px" }}>{ this.props.preview ? ('id' in item ? item.enTitle: item.title ) : item.enTitle }</Item></Box>: ''}
            {/* 所属来源的LOGO */}
          <Box center style={{ fontSize: 14, marginTop: 18 }}>
            {item.userType === 0 ? (
              util.getNewSourceLogoByQiNiu(this.state.source, {
                height: 24,
                width: 24,
                borderRadius: 99,
                marginRight: 5,
              })
            ) : (
              <img
                src={this.state.source && this.state.source.logo || "/assets/icons/sources/default.svg"}
                onError={util.handleSourceLogoError}
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 99,
                  marginRight: 10,
                }}
              />
            )}
            {item.userType === 0
              ? util.getNewSourceName(this.state.source)
              : this.state.source && this.state.source.sourceName}
            <div style={{ margin: "0 5px" }}>&middot;</div>
            <span className="text-minor">
              {util.formatTs(
                this.props.preview ? Date.now() : item.onlineTime,
                "%Y/%m/%d"
              )}
            </span>
          </Box>
        </div>
        <div style={{ padding: 24 }}>
          <Tabs
            activeKey={`${this.props.paperIndex}`}
            type="card"
            onChange={(e) => {
              this.props.onSelectPaper && this.props.onSelectPaper(parseInt(e));
            }}
          >
            {_.map(paperList, (itm, idx) => {
              return (
                <TabPane
                  key={idx}
                  tab={
                    paperList.length === 1
                      ? "论文"
                      : `论文${[
                        "一",
                        "二",
                        "三",
                        "四",
                        "五",
                        "六",
                        "七",
                        "八",
                        "九",
                        "十",
                        "十一",
                        "十二",
                        "十三",
                        "十四",
                        "十五",
                        "十六",
                        "十七",
                        "十八",
                        "十九",
                        "二十",
                      ][idx]
                      }`
                  }
                >
                  {selectedPaper.doi ? (
                    <div
                      style={{
                        background: "#f5f5f5",
                        padding: "16px 24px",
                        marginBottom: 30,
                        color: "#666",
                        fontSize: 14,
                        lineHeight: 2,
                      }}
                    >
                      <div>论文标题：{selectedPaper.title}</div>
                      <div ref="authors">
                        {this.state.collapseAuthors ? (
                          this.state.showAuthors ? (
                            <div>
                              作者：{(selectedPaper.authors || []).join(", ")}
                              <div
                                className="text-primary clickable"
                                style={{ fontSize: 12, textAlign: "right" }}
                                onClick={() =>
                                  this.setState({ showAuthors: false })
                                }
                              >
                                收起
                                <Icon name="chevron up" />
                              </div>
                            </div>
                          ) : (
                            <Box center style={{ overflow: "hidden" }}>
                              <Item flex className="ellipsis">
                                作者：{(selectedPaper.authors || []).join(", ")}
                              </Item>
                              {util.minWidth(5)}
                              <div
                                className="text-primary clickable"
                                style={{ fontSize: 12 }}
                                onClick={() =>
                                  this.setState({ showAuthors: true })
                                }
                              >
                                查看更多
                                <Icon name="chevron down" />
                              </div>
                            </Box>
                          )
                        ) : (
                          <div>
                            作者：{(selectedPaper.authors || []).join(", ")}
                          </div>
                        )}
                      </div>
                      <div>
                        期刊：
                        <Popover
                          trigger="hover"
                          placement="bottomLeft"
                          content={
                            <div>
                              <div className="periodical-url">
                                {this.state.periodical &&
                                  this.state.periodical.url && (
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={this.state.periodical.url}
                                      onClick={() => {
                                        actions.api.thesis.countJournalOfficialSiteClickNum({id: item.id, paperId: selectedPaper.id, type: 'desktop'})
                                      }}
                                    >
                                      进入期刊官网
                                    </a>
                                  )}
                              </div>
                              <div className="periodical-more">
                                <a
                                  target="_blank"
                                  className="text-primary"
                                  href={`/searchall?tab=theses&query=journals: ${encodeURIComponent(
                                    selectedPaper.checkname ||
                                    selectedPaper.journal
                                  )}`}
                                >
                                  阅读本刊更多导读
                                </a>
                              </div>
                            </div>
                          }
                        >
                          <span
                            className="text-primary"
                            style={{
                              textDecoration: "underline",
                              fontStyle:
                                selectedPaper.type &&
                                  selectedPaper.type === "期刊"
                                  ? "italic"
                                  : "normal",
                            }}
                          >
                            {selectedPaper.checkname || selectedPaper.journal}
                          </span>
                        </Popover>
                      </div>
                      <div>
                        发表时间：
                        {util.formatSystemDate(selectedPaper.publishDate)}
                      </div>
                      <div>数字识别码：{selectedPaper.doi}</div>
                      <div
                        style={{
                          display: util.htmlToPlain(selectedPaper.summary, true)
                            ? "block"
                            : "none",
                        }}
                      >
                        {this.state.showSummary ? (
                          <div>
                            摘要：
                            <span
                              className="user-html"
                              style={{
                                fontSize: "inherit",
                                lineHeight: "inherit",
                                color: "#666",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: util.filterXSS(selectedPaper.summary),
                              }}
                            />
                            <div
                              className="text-primary clickable"
                              style={{ fontSize: 12, textAlign: "right" }}
                              onClick={() =>
                                this.setState({ showSummary: false })
                              }
                            >
                              收起
                              <Icon name="chevron up" />
                            </div>
                          </div>
                        ) : (
                          <Box center style={{ overflow: "hidden" }}>
                            <Item flex className="ellipsis">
                              摘要：
                              {util.htmlToPlain(selectedPaper.summary, true)}
                            </Item>
                            {util.minWidth(5)}
                            <div
                              className="text-primary clickable"
                              style={{ fontSize: 12 }}
                              onClick={() =>
                                this.setState({ showSummary: true })
                              }
                            >
                              查看更多
                              <Icon name="chevron down" />
                            </div>
                          </Box>
                        )}

                        {this.state.isSummaryZh ? (
                          ""
                        ) : this.state.showZhSummary ? (
                          <div>
                            <div style={{ color: '#d70c18' }}><span style={{ fontWeight: 'bold' }}>摘要翻译</span>（由计算机程序完成，仅供参考，内容以英文原文为准）：</div>
                            <span
                              className="user-html"
                              style={{
                                fontSize: "inherit",
                                lineHeight: "inherit",
                                color: "#666",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `${selectedPaper.translateSummary}${selectedPaper.summary.length > 6000 ? '……' : ''}`,
                              }}
                            />
                            <div
                              className="text-primary clickable"
                              style={{ fontSize: 12, textAlign: "right" }}
                              onClick={() =>
                                this.setState({ showZhSummary: false })
                              }
                            >
                              收起
                              <Icon name="chevron up" />
                            </div>
                          </div>
                        ) : (
                          <div>
                            {"translateSummary" in selectedPaper &&
                              selectedPaper.translateSummary ? (
                              <>
                                <div style={{ color: '#d70c18' }}><span style={{ fontWeight: 'bold' }}>摘要翻译</span>（由计算机程序完成，仅供参考，内容以英文原文为准）：</div>
                                <Box>
                                  <Item flex className="ellipsis">
                                    {htmlToPlain(
                                      `${selectedPaper.translateSummary}`,
                                      true
                                    )}
                                  </Item>
                                  {util.minWidth(5)}
                                  <div
                                    className="text-primary clickable"
                                    style={{ fontSize: 12 }}
                                    onClick={() =>
                                      this.setState({ showZhSummary: true })
                                    }
                                  >
                                    查看更多
                                    <Icon name="chevron down" />
                                  </div>
                                </Box>
                              </>
                            ) : ""
                              // (
                              //   <>
                              //     <div style={{ color: '#d70c18' }}><span style={{ fontWeight: 'bold' }}>摘要翻译</span>（由计算机程序完成，仅供参考，内容以英文原文为准）：</div>
                              //     <Item flex className="ellipsis">
                              //       <span
                              //         dangerouslySetInnerHTML={{
                              //           __html: `翻译功能开小差，请在网络畅通时<a style="color:#290382" href="${window.location.href}">刷新</a>页面`,
                              //         }}
                              //       ></span>
                              //     </Item>
                              //   </>
                              // )
                            }
                          </div>
                        )}
                      </div>
                      {(selectedPaper.subjects || []).length ? (
                        <Box>
                          所属学科：
                          <Box flex wrap>
                            {selectedPaper.subjects.map((s) => (
                              <a
                                target="_blank"
                                href={`/searchall?tab=theses&filters.subject=${encodeURIComponent(
                                  s
                                )}&query=${encodeURIComponent(s)}`}
                              >
                                <div
                                  className="text-primary"
                                  style={{
                                    fontSize: 12,
                                    padding: "2px 4px",
                                    height: 14,
                                    borderRadius: 2,
                                    boxSizing: "content-box",
                                    border: "1px solid #290382",
                                    lineHeight: "14px",
                                    margin: "4px 8px",
                                    background: "#f5f5f5",
                                  }}
                                >
                                  {s}
                                </div>
                              </a>
                            ))}
                          </Box>
                        </Box>
                      ) : null}
                      {util.minHeight(12)}
                      <Button
                        style={{
                          fontSize: "14px",
                          padding: "2px 8px",
                          height: "28px",
                          lineHeight: "20px",
                        }}
                        icon="read"
                        onClick={() => {
                          // 统计点击次数
                          actions.api.thesis.countOriPaperClickNum({id: item.id, paperId: selectedPaper.id, type: 'desktop'})
                          window.open(selectedPaper.link);
                        }}
                      >
                        阅读论文原文
                      </Button>
                    </div>
                  ) : null}
                </TabPane>
              );
            })}
          </Tabs>
          {item.template ? (<div className="robot-tips">
            <div className="robot-container"><RobotIcon widthStr="13px" heightStr="13px" /></div>
            <div className="tips-container">本篇中文由机器翻译自动生成，仅供参考，内容以英文原文为准</div></div>):''}
          <span
            className="user-html"
            dangerouslySetInnerHTML={{ __html: util.filterXSS(item.template ? this.state.zhEnTemplate: item.content) }}
          />
          {/* 二维码 */}
          {util.wechatNewQRCode(this.state.source, {}, item.onlineTime)}
          <div className="text-minor user-html" style={{ marginTop: 24 }}>
            {item.original
              ? "未经许可请勿转载，获得授权请联系 contact@linkresearcher.com 或致电 010-85325984。"
              : `本文转载自“${item.userType === 0
                ? util.getSourceName(item.source)
                : item.source && item.source.sourceName
              }”。凡本网站转载、引用的文章、图片、音频、视频文件等资料的版权归版权所有人所有，如因此产生相关后果，将由版权所有人、原始发布者和内容提供者承担，如有侵权请尽快联系删除。`}
          </div>
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            <a
              href={`${location.origin}/information/5252ebfc-1c74-46fe-ab62-f2720afa32cc`}
            >
              <img src="/assets/images/editage_jump.png" />
            </a>
          </div>
        </div>
        <Box style={{ padding: "10px 24px 20px" }}>
          <div
            style={{
              fontSize: 14,
              fontWeight: 700,
              lineHeight: "30px",
              paddingRight: 10,
            }}
          >
            文章标签
          </div>
          <Box flex wrap>
            {item.tags.map((tag) => (
              <a
                className="text-primary hover-border-primary"
                target="__blank"
                href={`/searchall?tab=theses&query=${encodeURIComponent(tag)}`}
                style={{
                  fontSize: 12,
                  lineHeight: 1,
                  padding: 8,
                  border: "1px solid #eee",
                  margin: "0 0 10px 20px",
                  borderRadius: 2,
                }}
              >
                {tag}
              </a>
            ))}
          </Box>
        </Box>
      </div>
    );
  }
}
