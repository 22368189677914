import * as React from "react"

import * as util from "../util"
import * as _ from "lodash"
import { Box, Item } from "."
import * as mockxhr from "mockxhr"

interface Props {
    item: any
    preview?: boolean
}

interface State {
    source: any
}

export default class Detail extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            source: null
        }
    }

    componentDidMount() {
        let item = this.props.item;        
        // 修改时才有值的source
        const updateSource = item.source
        if(updateSource.sourceType !== "DEFAULT") {
            mockxhr.GET(`/api/source/${"id" in updateSource && updateSource.id ? updateSource.id : item.sourceKey}`, {}, resp => {
                this.setState({ source: resp })
            })
        } else {
            this.setState({source: updateSource})
        }
        
    }

    render() {
        let item = this.props.item
        
        return (
            <div style={{ background: "white" }}>
                <div style={{ padding: 24, borderBottom: "1px solid #ebebeb" }}>
                    <Box start>
                        <Item flex style={{ fontWeight: 700, fontSize: 28, lineHeight: "48px" }}>
                            {item.title}
                        </Item>
                        {util.minWidth(24)}
                    </Box>
                    <Box center style={{ fontSize: 14, marginTop: 18 }}>
                        {util.getNewSourceLogoByQiNiu(this.state.source, { height: 24, width: 24, borderRadius: 99, marginRight: 5 })}
                        {util.getNewSourceName(this.state.source)}
                        <div style={{ margin: "0 5px" }}>
                            &middot;
                        </div>
                        <span className="text-minor">
                            {util.formatTs(this.props.preview ? Date.now() : item.onlineTime, "%Y/%m/%d")}
                        </span>
                    </Box>
                </div>
                <div style={{ padding: 24 }}>
                    <span className="user-html" dangerouslySetInnerHTML={{ __html: util.filterXSS(item.content) }} />
                    {util.wechatNewQRCode(this.state.source, {}, item.onlineTime)}
                    <div className="text-minor user-html" style={{ marginTop: 24 }}>
                        {item.original ?
                            "未经许可请勿转载，获得授权请联系 contact@linkresearcher.com 或致电 010-85325984。" :
                            `本文转载自“${util.getNewSourceName(this.state.source)}”。凡本网站转载、引用的文章、图片、音频、视频文件等资料的版权归版权所有人所有，如因此产生相关后果，将由版权所有人、原始发布者和内容提供者承担，如有侵权请尽快联系删除。`
                        }
                    </div>
                    {
                      _.isArray(item.columns) && (item.columns.includes('科研进阶工具箱') ?
                      <div
                          style={{
                              marginTop: "20px",
                              textAlign: "center",
                          }}>
                          <a href={`https://www.aje.cn/`}>
                              <img src="https://cdn.linkresearcher.com/AJE4.png" style={{width: '100%'}}/>
                          </a>
                      </div> 
                  : null)
                    }
                    {
                        _.isArray(item.columns) && (item.columns.includes('SCI 发表之路') ?
                            <div
                                style={{
                                    marginTop: "20px",
                                    textAlign: "center",
                                }}>
                                <a href={`${location.origin}/verify/pagejumper?hmsr=1&hmpl=5&hmcu=&hmkw=&hmci=`}>
                                    <img src="/assets/images/editage_jump3.jpg"/>
                                </a>
                            </div> 
                        : null)
                    }
                </div>
            </div>
        )
    }
}
