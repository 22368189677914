import * as React from "react"
import * as mockxhr from "mockxhr"
import NotifyToast from "react-notify-toast"
import { get, set } from "object-path"
import * as _ from "lodash"
import { message } from 'antd'
import * as util from "../util"
import Detail from "./ThesisDetail2"
import Modal from "./Modal"
import PaperEdit from "./PaperEdit"
import { NewIcon } from "./TagIcon"
import RelevantEdit from "../components/RelevantEdit"
import SettingPanel from "../components/SettingPanel"
import UploadFile from "../components/UploadFile"
import { Button, Box, Icon, Item, Popup, elements } from "../components"
import { actions } from "../flux"
import FormChecker, { ErrorTooltip } from "../components/FormChecker";

interface Props {
    thesis: any
    title: string
    onFinish: any
    hideCancel?: boolean
    floatLeft?: boolean
    step?: number
    subjects: subject[]
    sourceList?: any[]
}

interface subject {
    value: string
}

interface State {
    preview: boolean
    form: any
    summaryEditor: any
    contentEditor: any
    cols: any[]
    thesisLinks: string[]
    step: number
    unlinkId: number
    columnList: string[]
    paperIndex: number
    btnLoading: boolean
}

export default class ThesisEdit extends React.Component<Props, State> {
    fetching = false
    submitted = false
    fetchingDOI = false
    fetchedPapers = {}
    formChecker = new FormChecker()

    constructor(props: Props) {
        super(props)
        this.state = {
            step: typeof this.props.step === "undefined" ?
                (this.props.thesis && this.props.thesis.id ? 1 : 0) : this.props.step,
            unlinkId: -1,
            preview: false,
            form: {
                ...this.props.thesis,
                // 设置【权重】初始值为1
                weight: this.props.thesis.weight || 1,
                // 设置【权重（首页）】初始值为1
                weight3: this.props.thesis.weight3 || 1,
                // 设置【权重（搜索页）】初始值为1
                weight2: this.props.thesis.weight2 || 1,
                // 设置【是否推荐】初始值为是
                popular: this.props.thesis.popular || true,
                // 是否科学60秒
                isScience60s: this.props.thesis.isScience60s || false,
                // 设置原创栏目，true是原创，false是转载
                origin: {
                    isOriginal: this.props.thesis.original
                }
            },
            summaryEditor: null,
            contentEditor: null,
            cols: [],
            thesisLinks: this.props.thesis && this.props.thesis.content ?
                this.parseLinksFromHTML(this.props.thesis.content) : [],
            columnList: ['weight', 'homeWeightPaper'],
            paperIndex: 0,
            btnLoading: false,
        }
        if (this.props.thesis && get(this.props.thesis, "source.originUrl")) {
            this.state.thesisLinks.push(get(this.props.thesis, "source.originUrl"))
        }
        this.columnDataFormat = this.columnDataFormat.bind(this)
    }

    componentDidMount() {
        console.log(this.state.form.origin.isOriginal, "isOriginal...")
        console.log(this.state.form, "state.form...")
        this.loadEditors()
        mockxhr.POST("/api/column/do?from=0&size=10&type=SEARCH", {
            columnDao: {columnName: ""},
            type: "SEARCH"
        }, res => {
            this.setState({ 
                cols: _.map(_.filter(_.map(res.hits, i => this.columnDataFormat(i)), j => j.type == "WeightPaper"), o => ({text: o.columnName, value: o.columnName, id: o.id}))
            })
        })
    }

    componentWillUnmount() {
        util.removeCacheObjItem('paperEditDoiCheckname')
    }

    parseLinksFromHTML(html) {
        let links: string[] = []
        let root = document.createElement("span")
        root.innerHTML = html
        let atags = root.querySelectorAll("a")
        for (let i = 0; i < atags.length; i++) {
            let href = atags[i].getAttribute("href")
            if (href) links.push(href)
        }
        let innerText = root.innerText
        let regex = /https?:\/\/\S+/g
        let match
        while (match = regex.exec(innerText)) links.push(match[0])
        links = links.filter(link => link.indexOf("://mp.weixin.qq.com") < 0)
            .map(link => link.split("").filter(c => c.charCodeAt(0) < 256).join(""))
        return links
    }

    loadEditors() {
        this.setState({
            contentEditor: util.createWangEditor(this, "contentEditor", "form.content"),
        }, () => {
            this.state.contentEditor.txt.html(this.state.form.content)
        })
    }

    testSave() {
        let { cover, title, papers } = this.state.form
        return !!(cover && title && papers && papers.length)
    }

    columnDataFormat(parm) {
        let obj = {}
        _.forEach(parm, (item, index) => {
            if (_.indexOf(this.state.columnList, index) < 0) {
                obj[index] = item
            } else {
                obj[index] = (item as string).split(";")[0]
                obj[`_${index}`] = (item as string).split(";")[1]
            }
        })
        return obj
    }

    checkHtmlEmpty (htmlContent: string) {
        const dom = document.createElement('div') ;
        dom.innerHTML = htmlContent;
        return (dom.textContent || '').trim() == ''
    }

    render() {
        let session = actions.local.session()
        if (!session) return null

        let that = this
        let form = this.state.form
        let isAdmin = location.pathname === "/admin"
        let notMine = form.userType === 1 && isAdmin && !(session.type === 0)

        form.source = form.source || {}
        form.relevant = form.relevant || []
        form.papers = form.papers || []
        form.paperList = form.paperList || []
        if (form.userType === undefined && isAdmin) form.userType = 0

        return this.state.preview ?
            [
                <div>
                    <div className="text-center bg-color-primary clickable"
                        style={{ lineHeight: 3, color: "white" }}
                        onClick={() => this.setState({ preview: false }, () => this.loadEditors())}>
                        正在预览论文，点击返回编辑
                    </div>
                </div>
                ,
                this.props.floatLeft ?
                    <Box centerJustified style={{ background: "#fff", paddingBottom: 24 }}>
                        <Item flex style={{ maxWidth: 850 }}>
                            <Detail item={form} preview paperIndex={this.state.paperIndex} onSelectPaper={i => {this.setState({ paperIndex: i })}} />
                        </Item>
                    </Box>
                    :
                    <Box centerJustified style={{ paddingBottom: 24 }}>
                        <div style={{ width: 850 }}>
                            <Detail item={form} preview paperIndex={this.state.paperIndex} onSelectPaper={i => {this.setState({ paperIndex: i })}} />
                        </div>
                    </Box>
            ] : (
                <Item relative className="bg-color-1" style={{ fontSize: "1rem" }}>
                    <SettingPanel title={this.props.title}>
                        <div ref="settingPanel" style={{ padding: "2.5rem 1.875rem", lineHeight: "24px" }}>
                            <div>
                                {elements.settingTitle(null, "已关联的论文", "", {})}
                                {util.minHeight(15)}
                                {(form.paperList || []).length === 0 ? util.textMinor("无", {
                                    marginBottom: 15,
                                    display: "block",
                                }) : ""}
                                {(form.paperList || []).map((p, i) =>
                                    <Box key={i} center style={{ fontSize: 14, marginBottom: 15 }}>
                                        <Item flex style={{ overflow: "hidden" }}>
                                            <div className="ellipsis">
                                                论文[{i + 1}]
                                                {(this.state.form.id && p.summary === undefined)?
                                                    <span className="text-minor" style={{ marginLeft: "2em" }}>{p.title.substr(0,55)}{<span style={{color: 'red',fontWeight: 'bold'}}>&nbsp;&nbsp;摘要信息为空</span>}</span>
                                                    :<span className="text-minor" style={{ marginLeft: "2em" }}>{p.title}</span>}

                                            </div>
                                        </Item>
                                        {this.state.step === 0 && (
                                            (isAdmin && session.type === 0) ||
                                            p.creatorId === session.id ||
                                            p.creatorId === session.accountId) ?
                                            <div className="clickable text-error"
                                                style={{
                                                    fontSize: 13, borderBottom: "1px solid #db2828",
                                                    lineHeight: "18px", marginLeft: "1em",
                                                }}
                                                onClick={() => {
                                                    let paperUpdate = that.refs.paperUpdate as PaperEdit
                                                    paperUpdate.show(util.cloneJSON(p), newPaper => {
                                                        form.paperList[i] = newPaper
                                                        that.setState({})
                                                    })
                                                }}>
                                                编辑元数据
                                            </div>
                                            :
                                            <div
                                                style={{
                                                    fontSize: 13, borderBottom: "1px solid #db2828",
                                                    lineHeight: "18px", marginLeft: "1em",
                                                    opacity: 0, cursor: "default",
                                                }}>
                                                编辑元数据
                                            </div>
                                        }
                                        {this.state.step === 0 ?
                                            <div className="clickable text-error"
                                                style={{
                                                    fontSize: 13, borderBottom: "1px solid #db2828",
                                                    lineHeight: "18px", marginLeft: "1em",
                                                }}
                                                onClick={() => {
                                                    this.setState({ unlinkId: i }, () => {
                                                        (this.refs.unlink as Modal).show()
                                                    })
                                                }}>
                                                取消关联
                                            </div>
                                            : null
                                        }
                                        <a target="_blank" href={p.link} style={{ marginLeft: "1em" }}>
                                            <Icon name="external alternate" style={{ verticalAlign: "sub" }} />
                                        </a>
                                    </Box>
                                )}
                            </div>

                            <Modal ref="unlink" title="取消关联论文" innerWidth="30rem"
                                confirmText cancelText
                                onConfirm={() => {
                                    form.papers.splice(this.state.unlinkId, 1)
                                    form.paperList.splice(this.state.unlinkId, 1)
                                    this.setState({})
                                }}
                                content={
                                    <div className="text-center">
                                        是否取消关联该论文？
                                    </div>
                                } />

                            {util.minHeight(15)}
                            {this.state.step === 0 ?
                                <div>
                                    {elements.settingTitle(null, "上传新的论文", "", {})}
                                    {util.minHeight(15)}
                                    <Box center>
                                        {elements.formSelect(that, "form.link$", {
                                            placeholder: "请输入论文链接，必须以 http:// 或 https:// 开头",
                                            options: util.unique([form.link, ...this.state.thesisLinks].filter(link => !!link))
                                                .map(link => ({ value: link, text: link })),
                                            search: true,
                                            allowAdditions: true,
                                            additionLabel: "",
                                            onAddItem(e, { value }) {
                                                value = value.trim()
                                                that.setState({
                                                    thesisLinks: util.unique([value, ...that.state.thesisLinks])
                                                })
                                            },
                                        })}
                                        {util.minWidth(15)}
                                        <a href={get(that.state, "form.link$")} target="_blank">
                                            <Icon name="external alternate" className="text-primary"
                                                style={{ fontSize: 20, opacity: get(that.state, "form.link$") ? 1 : 0.5 }} />
                                        </a>
                                    </Box>
                                </div>
                                : null
                            }
                            {util.minHeight(20)}
                            <Box hidden={this.state.step !== 0}>
                                <Item flex>
                                    {elements.formInput(that, "form.doi$", {
                                        placeholder: "请输入 DOI、PMID、arXiv 等论文数字识别码",
                                        type: "trim",
                                    })}
                                </Item>
                                {util.minWidth(20)}
                                <Popup position="top center" on="hover"
                                    trigger={
                                        <Button size="small"
                                            loading={this.state.btnLoading}
                                            basic={!get(that.state, "form.link$") || this.fetchingDOI ? false : true}
                                            disabled={!get(that.state, "form.link$") || this.fetchingDOI}
                                            onClick={() => {
                                                let link = get(that.state, "form.link$", "")
                                                if (!/^https?:/.test(link)) return
                                                if (this.fetchingDOI) return
                                                this.fetchingDOI = true
                                                that.setState({})
                                                this.setState({
                                                    btnLoading: true,
                                                }, () => {
                                                    mockxhr.POST('https://data.linkresearcher.com/scrary/theses', {url: link}, r => {
                                                        this.fetchedPapers[link] = r.data
                                                        set(that.state, "form.doi$", r.data.identCode)
                                                        this.fetchingDOI = false
                                                        that.setState({})
                                                        this.setState({btnLoading: false})
                                                    }, err => {
                                                        this.fetchingDOI = false
                                                        that.setState({})
                                                        this.setState({btnLoading: false})
                                                        if (err.status === 401) {
                                                            message.error(JSON.parse(err.response).msg)
                                                        }
                                                    })
                                                })
                                            }}>
                                            自动获取
                                        </Button>
                                    }
                                    content={
                                        <div style={{ whiteSpace: "pre-wrap" }}>
                                            目前已经支持的网站有：
                                            http://journals.plos.org
                                            http://www.pnas.org
                                            http://www.sciencemag.org
                                            http://www.tandfonline.com
                                            https://academic.oup.com
                                            https://arxiv.org
                                            https://journals.aps.org
                                            https://link.springer.com
                                            https://pubs.acs.org
                                            https://www.biomedcentral.com
                                            https://www.cell.com
                                            https://www.nature.com
                                            https://www.sciencedirect.com
                                            https://www.thelancet.com
                                            https://pubs.acs.org
                                            https://www.ahajournals.org
                                            https://www.frontiersin.org
                                            https://onlinelibrary.wiley.com
                                            https://gut.bmj.com
                                            https://www.biorxiv.org
                                            https://jamanetwork.com
                                            https://journals.sagepub.com
                                            https://iopscience.iop.org
                                            https://www.jci.org（已完成 没有标签缩写）
                                            https://www.bmj.com
                                            https://ieeexplore.ieee.org
                                            https://dl.acm.org/dl.cfm
                                            https://www.geochemicalperspectivesletters.org
                                            https://physics.aps.org
                                            https://www.nejm.org
                                        </div>
                                    } />
                            </Box>
                            {this.state.step === 0 ?
                                <Box style={{ marginTop: 20 }}>
                                    <Button primary style={{ width: "11rem" }}
                                        loading = {that.state.btnLoading}
                                        disabled={!form.doi$}
                                        onClick={() => {                                            
                                            if (form.papers.indexOf(form.doi$) >= 0) {
                                                util.toast.error("您已上传该论文")
                                                return
                                            }
                                            if (that.state.btnLoading) return
                                            that.setState({btnLoading: true}, () => {
                                                mockxhr.POST("/api/papers/search?size=1&from=0&type=_", {
                                                    filters: { doi: form.doi$ }
                                                }, res => {
                                                    that.setState({btnLoading: false})
                                                    let paperEdit = this.refs.paperEdit as PaperEdit
                                                    if (res.total > 0) {
                                                        form.papers.push(res.hits[0].doi)
                                                        form.paperList.push(res.hits[0])
                                                        util.addCacheObjItem('paperEditDoiCheckname', res.hits[0].doi, res.hits[0].checkname)
                                                        form.doi$ = form.link$ = ""
                                                        that.setState({})
                                                    } else {
                                                        let paper = {
                                                            doi: form.doi$ || "",
                                                            link: form.link$ || "",
                                                            title: "",
                                                        }
    
                                                        // “自动获取”成功时数据被覆盖
                                                        if (this.fetchedPapers[form.link$]) {
                                                            util.mergePaper(paper, this.fetchedPapers[form.link$])
                                                        }
    
                                                        paperEdit.show(paper, newPaper => {
                                                            form.papers.push(newPaper.doi)
                                                            form.paperList.push(newPaper)
                                                            form.doi$ = form.link$ = ""
                                                            that.setState({})
                                                        })
                                                    }
                                                })
                                            })
                                        }}>
                                        上传
                                    </Button>
                                    {util.minWidth(20)}
                                    <Button basic style={{ width: "11rem" }}
                                        onClick={() => {
                                            if (form.papers.length === 0) {
                                                util.toast.error("至少关联一篇论文")
                                                if (this.refs.settingPanel) {
                                                    (this.refs.settingPanel as HTMLElement).scrollIntoView()
                                                }
                                                return
                                            }
                                            /*if (this.checkHtmlEmpty(form.summary)) {
                                                util.toast.error("论文摘要信息不能为空")
                                                return
                                            }*/
                                            if (form.id) {
                                                this.setState({ step: 1 })
                                                return
                                            }
                                            mockxhr.POST("/api/theses/search_by_doi", form.papers, res => {
                                                if (res.total > 0) {
                                                    util.toast.error("已存在相似的论文导读")
                                                } else {
                                                    this.setState({ step: 1 }, () => {
                                                        if (window.location.hash === '#/Thesis') {
                                                            (this.refs.modal as any).show()
                                                        }
                                                    })
                                                }
                                            })
                                        }}>
                                        编辑论文导读
                                    </Button>
                                </Box>
                                :
                                <Box hidden={notMine} style={{ marginTop: -15 }}>
                                    <Button basic style={{ width: "11rem" }}
                                        onClick={() => this.setState({ step: 0 })}>
                                        编辑论文列表
                                    </Button>
                                </Box>
                            }
                        </div>

                        <PaperEdit subjects={this.props.subjects} ref="paperEdit" />
                        <PaperEdit subjects={this.props.subjects} ref="paperUpdate" update />

                        <div className="line-height"
                            style={{ padding: "0 1.875rem 2.5rem", display: this.state.step === 1 ? "block" : "none" }}>
                            {isAdmin ? [
                                elements.settingTitleIcon("是否启用双语模板", <NewIcon  />),
                                util.minHeight("1.25rem"),
                                elements.formInput4(this, "form.template", {
                                    type: "select",
                                    options: [
                                        { value: false, text: "否" },
                                        { value: true, text: "英译中" },
                                    ]
                                }),
                            ] : null}
                            {session.type === 0 ? [
                                elements.settingTitleIcon("翻译模型切换", <NewIcon  />),
                                util.minHeight("1.25rem"),
                                elements.formInput4(this, "form.translateModel", {
                                    type: "select",
                                    options: [
                                        { value: 1, text: "百度翻译" },
                                        { value: 2, text: "DeepSeek" },
                                    ]
                                }),
                            ] : null}
                            {
                                [   
                                    <ErrorTooltip checker={this.formChecker} id="form.title" 
                                        getTip={() => {
                                            if(!this.state.form.title) {
                                                return "必填"
                                            }
                                        }}
                                        arrowDirection = "left"
                                        style = {{right: "4rem"}}
                                    />,
                                    elements.settingItem(this, "form.title", null, "导读标题", "", {
                                        disabled: notMine,
                                    })
                                ]
                            }
                            { form.id && form.template && elements.settingItemIcon(this, "form.enTitle", "英文标题", <NewIcon />, {
                                disabled: notMine,
                            })}
                            {
                                [
                                    <ErrorTooltip checker={this.formChecker} id = "form.content"
                                        getTip={() => {
                                            if(!this.state.form.content) {
                                                return "必填"
                                            } else {
                                                const htmlEl = document.createElement("div")
                                                htmlEl.innerHTML = this.state.form.content
                                                if(!String(htmlEl.innerText).trim()) {
                                                    return "必填"
                                                }
                                            }
                                        }}
                                        arrowDirection = "left"
                                        style = {{right: "4rem"}}
                                    />,
                                    elements.settingTitle(null, "导读正文", "")
                                ]
                            }
                            {util.minHeight("1.25rem")}
                            <div ref="contentEditor" style={{ display: notMine ? "none" : "block" }}><p /></div>
                            {notMine ?
                                <span className="user-html" dangerouslySetInnerHTML={{ __html: util.filterXSS(form.content) }} />
                                : null
                            }
                            {util.minHeight("1.25rem")}
                            {
                                [
                                    <ErrorTooltip checker={this.formChecker} id = "form.tags" 
                                        getTip={() => {
                                            if(this.state.form.tags.length <= 0) {
                                                return "必填"
                                            }
                                        }}
                                        arrowDirection = "left"
                                        style = {{right: "4rem"}}
                                    />,
                                    elements.settingItem(this, "form.tags", null, "导读标签", "", {
                                        type: "tags",
                                        splitable: true,
                                        disabled: notMine,
                                    })
                                ]
                            }
                            {
                                [   
                                    <ErrorTooltip checker={this.formChecker} id = "form.cover" 
                                        getTip={() => {
                                            if(!this.state.form.cover) {
                                                return "必填"
                                            }
                                        }}
                                        arrowDirection="left"
                                        style = {{right: "4rem"}}
                                    />,
                                    elements.settingTitle(null, "导读封面", "", {
                                        id: "thesis-tags"
                                    })
                                ]
                            }
                            {util.minHeight("1.25rem")}
                            <UploadFile isAdmin={isAdmin} tags={form.tags} title="上传封面" 
                                url={form.cover} disabled={notMine}
                                textElemId={this.state.contentEditor && this.state.contentEditor.textElemId}
                                style={{
                                    height: "10rem",
                                    background: "rgb(250, 250, 250)",
                                    border: "1px solid rgba(34,36,38,.15)",
                                    borderRadius: ".28571429rem",
                                }}
                                onChange={resp => {
                                    this.state.form.cover = resp.url
                                    this.setState({})
                                }} 
                                />
                            {util.minHeight("1.25rem")} 
                            {isAdmin ? [
                                <ErrorTooltip checker={this.formChecker} id = "form.columns" 
                                    getTip={() => {
                                        if(this.state.form.columns.length <= 0) {
                                            return "必填"
                                        }
                                    }}
                                    arrowDirection = "left"
                                    style = {{ right: "4rem" }}
                                />,
                                elements.settingTitle(null, "所属栏目", ""),
                                util.minHeight("1.25rem"),
                                elements.formInput4(this, "form.columns", {
                                    type: "tags",
                                    placeholder: "请输入论文所属栏目",
                                    options: this.state.cols,
                                    inputBesidesOptions: true,
                                    couldNotEdit: true,
                                }),
                            ] : null}
                            {form.userType === 0 ? [
                                <ErrorTooltip checker={this.formChecker} id = "form.source" 
                                    getTip={() => {
                                        if(!this.state.form.source.sourceType || this.state.form.source.sourceType !== "DEFAULT" && !this.state.form.source.sourceName) {
                                            return "必填"
                                        }
                                    }}
                                    arrowDirection = "left"
                                    style = {{ right: "4rem" }}
                                />,
                                elements.settingTitle(null, "来源", ""),
                                util.minHeight("1.25rem"),
                                elements.sourceEdit(form.source, () => this.setState({}), this.props.sourceList, form.origin),
                                util.minHeight("1.25rem"),
                                elements.settingTitle(null, "原创", ""),
                                util.minHeight("1.25rem"),
                                elements.formInput4(this, "form.origin.isOriginal", {
                                    type: "select",
                                    options: [
                                        { value: true, text: "原创" },
                                        { value: false, text: "转载" },
                                    ]
                                }),
                                elements.settingTitle4("相关推荐"),
                                <RelevantEdit relevant={form.relevant} length={3} />
                                ,
                                util.minHeight("0.25rem")
                            ] : null}
                            {isAdmin ? [
                                <ErrorTooltip checker={this.formChecker} id = "form.weight" 
                                    getTip={() => {
                                        if(this.state.form.weight === "") {
                                            return "必填"
                                        }
                                    }}
                                    arrowDirection = "left"
                                    style = {{ right: "4rem" }}
                                />,
                                elements.settingTitle4("权重"),
                                elements.formInput4(this, "form.weight", {
                                    placeholder: "1-100",
                                    _onChange: util.intRange(1, 100),
                                }),
                            ] : null}
                            {isAdmin ? [
                                <ErrorTooltip checker={this.formChecker} id = "form.weight3" 
                                    getTip={() => {
                                        if(this.state.form.weight3 === "") {
                                            return "必填"
                                        }
                                    }}
                                    arrowDirection = "left"
                                    style = {{ right: "4rem" }}
                                />,
                                elements.settingTitle4("权重（首页）"),
                                elements.formInput4(this, "form.weight3", {
                                    placeholder: "1-100",
                                    _onChange: util.intRange(1, 100),
                                }),
                            ] : null}
                            {isAdmin ? [
                                <ErrorTooltip checker={this.formChecker} id = "form.weight2" 
                                    getTip={() => {
                                        if(this.state.form.weight2 === "") {
                                            return "必填"
                                        }
                                    }}
                                    arrowDirection = "left"
                                    style = {{ right: "4rem" }}
                                />,
                                elements.settingTitle4("权重（搜索页）"),
                                elements.formInput4(this, "form.weight2", {
                                    placeholder: "1-100",
                                    _onChange: util.intRange(1, 100),
                                }),
                            ] : null}
                            {isAdmin ? [
                                <ErrorTooltip checker={this.formChecker} id = "form.popular" 
                                    getTip={() => {
                                        if(this.state.form.popular === undefined) {
                                            return "必填"
                                        }
                                    }}
                                    arrowDirection = "left"
                                    style = {{ right: "4rem" }}
                                />,
                                elements.settingTitle4("是否推荐"),
                                elements.formInput4(this, "form.popular", {
                                    type: "select",
                                    options: [
                                        { value: false, text: "否" },
                                        { value: true, text: "是" },
                                    ]
                                }),
                            ] : null}
                            {isAdmin ? [
                                elements.settingTitle(null, "状态", ""),
                                util.minHeight("1.25rem"),
                                elements.formInput4(this, "form.status", {
                                    type: "select",
                                    options: [
                                        { value: false, text: "下线" },
                                        { value: true, text: "在线" },
                                    ]
                                }),
                            ] : null}
                            {util.minHeight("1.875rem")}
                            <Box center>
                                <Button primary style={{ width: "11rem" }}
                                    onClick={() => {
                                        if (this.state.btnLoading) return
                                        this.setState({btnLoading: true}, () => {
                                            if (form.id) {
                                                // 修改类型的发布
                                                this.formChecker.check(() => {
                                                    if (form.papers.length === 0) {
                                                        util.toast.error("至少关联一篇论文")
                                                        if (this.refs.settingPanel) {
                                                            (this.refs.settingPanel as HTMLElement).scrollIntoView()
                                                        }
                                                        this.setState({btnLoading: false})
                                                        return
                                                    }
                                                    /*if (this.checkHtmlEmpty(form.summary)) {
                                                        util.toast.error("论文摘要信息不能为空")
                                                        this.setState({btnLoading: false})
                                                        return
                                                    }*/
                                                    // 无报错的情况下执行 
                                                    this.setState({
                                                        form: {
                                                            ...this.state.form,
                                                            enTextList: this.state.form.template ? util.parseContent(this.state.form.content) : [],
                                                            paperList: _.map(this.state.form.paperList, itm => {
                                                                return {
                                                                    ...itm,
                                                                    subjectId: _.map(_.filter(this.props.subjects, i => itm.subjects.includes(i.value)), j => j.id),
                                                                }
                                                            }),
                                                            columnsId: _.map(_.filter(this.state.cols, i => this.state.form.columns.includes(i.value)), j => j.id),
                                                            original: this.state.form.origin.isOriginal
                                                        }
                                                    }, () => {
                                                        actions.api.thesis.update(this.state.form, () => {
                                                            this.setState({btnLoading: false})
                                                            if (this.props.onFinish) this.props.onFinish()
                                                        }, (xhr) => {
                                                            this.setState({btnLoading: false})
                                                            if(xhr.status === 612){
                                                                util.toast.error("论文摘要信息不能为空")
                                                            }
                                                        }
                                                        )})
                                                }, () => {
                                                    // 如果有报错只执行它
                                                    let error = document.querySelector(".form-error") as HTMLElement
                                                    error.scrollIntoView()
                                                    this.setState({btnLoading: false})
                                                })
    
                                                
                                            } else {
                                                // 新增类型的发布
                                                // 同时也是爬虫打开的页面
                                                this.formChecker.check(() => {
                                                    // 无报错的情况下执行 ==》 新增
                                                    if (form.papers.length === 0) {
                                                        util.toast.error("至少关联一篇论文")
                                                        if (this.refs.settingPanel) {
                                                            (this.refs.settingPanel as HTMLElement).scrollIntoView()
                                                        }
                                                        this.setState({btnLoading: false})
                                                        return
                                                    }
                                                    /*if (this.checkHtmlEmpty(form.summary)) {
                                                        util.toast.error("论文摘要信息不能为空")
                                                        this.setState({btnLoading: false})
                                                        return
                                                    }*/
                                                    this.setState({
                                                        form: {
                                                            ...this.state.form,
                                                            enTextList: this.state.form.template ? util.parseContent(this.state.form.content) : [],
                                                            paperList: _.map(this.state.form.paperList, itm => {
                                                                return {
                                                                    ...itm,
                                                                    subjectId: _.map(_.filter(this.props.subjects, i => itm.subjects.includes(i.value)), j => j.id),
                                                                }
                                                            }),
                                                            columnsId: _.map(_.filter(this.state.cols, i => this.state.form.columns.includes(i.value)), j => j.id),
                                                            original: this.state.form.origin.isOriginal
                                                        }
                                                    }, () => {
                                                        console.log(this.state.form)
                                                        actions.api.thesis.create(this.state.form, () => {
                                                            this.setState({btnLoading: false})
                                                            if (this.props.onFinish) this.props.onFinish()
                                                        }, (xhr) => {
                                                            this.setState({btnLoading: false})
                                                            if(xhr.status === 612){
                                                                util.toast.error("论文摘要信息不能为空")
                                                            }
                                                        })
                                                    })   
                                                }, () => {
                                                    // 如果有报错只执行它
                                                    let error = document.querySelector(".form-error") as HTMLElement
                                                    error.scrollIntoView()
                                                    this.setState({btnLoading: false})
                                                })                                 
                                            }
                                        })
                                    }}>
                                    发布
                                </Button>
                                {util.minWidth("1.25rem")}
                                {this.props.hideCancel ? null : [
                                    <Button basic style={{ width: "11rem" }} onClick={() => {
                                        this.props.onFinish() 
                                        this.setState({btnLoading: false})
                                        }}>
                                        取消
                                    </Button>
                                    ,
                                    util.minWidth("1.25rem")
                                ]}
                                <div className="text-primary clickable"
                                    onClick={() => {
                                        this.setState({ preview: true }, () => {
                                            (document.querySelector("#root") as any).scrollTop = 0
                                        })
                                    }}>
                                    <u>预览</u>
                                </div>
                            </Box>
                        </div>
                        
                    <Modal title="请选择" innerWidth="30rem" ref="modal"
                        confirmText cancelText style={{ fontSize: "1rem" }}
                        onConfirm={() => {
                            this.setState({
                                form: {
                                    ...this.state.form,
                                    content: `
                                        <br />
                                        <p class="w-e-collapse-title-down" style="text-align:left;"><i class="fa fa-angle-double-down w-e-collapse">&nbsp;[听力全文]（点击展开/收起）</i></p>
                                        <br>
                                        <div class="w-e-collapse-section">输入可折叠的内容</div>
                                        <br>
                                        <br>
                                        <p class="w-e-collapse-title-down" style="text-align:left;"><i class="fa fa-angle-double-down w-e-collapse">&nbsp;[重难点词汇、短语]（点击展开/收起）</i></p>
                                        <br>
                                        <div class="w-e-collapse-section">输入可折叠的内容</div>
                                        <br>
                                        <br>
                                        <p class="w-e-collapse-title-down" style="text-align:left;"><i class="fa fa-angle-double-down w-e-collapse">&nbsp;[参考译文]（点击展开/收起）</i></p>
                                        <br>
                                        <div class="w-e-collapse-section">输入可折叠的内容</div>
                                        <br>
                                        <br>
                                    `,
                                    isScience60s: true
                                }
                            }, () => this.loadEditors())
                        }}
                        content={
                            <div className="text-gray text-center">
                                是否为科学60秒？
                            </div>
                        } />
                    </SettingPanel>

                    <NotifyToast />
                </Item>
            )
    }
}
